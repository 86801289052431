import $ from 'jquery';

$(document).ready(function() {

  // Change background color of navbar on scroll / if toggler is clicked
  // var scroll_start = 0;
  // var startchange = $('.site-wrapper');
  // var offset = startchange.offset();
  // if (startchange.length){
  //   $(document).scroll(function() { 
  //     scroll_start = $(this).scrollTop();
  //     if (scroll_start > offset.top) {
  //       $('.navbar').css('background-color', '#15233d');
  //     } else if ($('.navbar').hasClass('navbar-color')) {
  //       return
  //     } else {
  //       $('.navbar').css('background-color', 'transparent');
  //     }
  //   });
  // }
  // $('.navbar-toggler').click(function () {
  //   $('.navbar').toggleClass('navbar-color');
  // })

  // Quick and dirty smooth scroll to #hash on .scroll-arrow (eg. home page)
  $('a.smooth-scroll').on('click', function(event) {
    if (this.hash !== '') {
      event.preventDefault();
      var hash = this.hash;
      var scrollH = $(hash).offset().top - 130
    
      $('html, body').animate({
        scrollTop: $(hash).offset().top - 130
      }, 800, function(){   
        // If we want to add the #hash to the URL: 
        // window.location.hash = hash;
      });
    }
  });

});